@import '../../assets/css/settings.scss';

button.close {
    outline: none;
}

a {
    color: $color-primary;
}

.panel-info {
    border: 1px solid $color-primary;
}

.panel-primary {
    border-color: #337ab7;
}

/* home */

.add-paragraph {
    color: $color-primary;
}

.RowTitle {
    color: #706f6e;
    font-weight: 500;
}

.cx_paragraph-D7 header {
    min-height: 300px;
    font-size: 32px;
    background-size: cover;
    background-image: url('/images/background.jpg');
    color: #fff;
    padding: 40px;
    height: auto;
}

.paragraph-text {
    display: block;
    white-space: normal;
    font-family: Arial, sans-serif;
    font-size: 12px;
}

.paragraph-text .span {
    padding: 10px;
    background: #e9f5f3;
}

.reviews {
    display: block;
    white-space: normal;
    float: none;
}

.panel shoutout {
    border-color: #fff;
    font-size: 20px;
}

.icon-image {
    width: 100%;
    padding: 10px;
    text-align: center;
    margin: 10px;
    height: auto;
}

.icon-image-home {
    width: 100%;
    padding: 10px;
    text-align: center;
    margin: 10px;
    height: auto;
}

.icon-image-home img {
    width: 128px;
    height: auto;
}

.cx_panel-primary-light {
    border: 1px solid #ddd;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 15px;
}

.cx_panel-primary-light > .card-heading,
.cx_panel-primary-light > .card-heading a {
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    text-decoration: none;
}

.cx_panel-heading-collapse-btn {
    display: none;
}

.panel-collapse {
    border: 0;
}

/*          Hero Headers        */
/********************************/

.hero {
    position: absolute;
    top: 50%;
    left: 42%;
    font-size: 2vw;
    z-index: 3;
    color: #fff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    -o-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

.hero h2 {
    font-size: 4vw;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 0;
}

.fade-carousel .carousel-inner .item .hero {
    opacity: 0;
    -webkit-transition: 2s all ease-in-out 0.1s;
    -moz-transition: 2s all ease-in-out 0.1s;
    -ms-transition: 2s all ease-in-out 0.1s;
    -o-transition: 2s all ease-in-out 0.1s;
    transition: 2s all ease-in-out 0.1s;
}

.fade-carousel .carousel-inner .item.active .hero {
    opacity: 1;
    -webkit-transition: 2s all ease-in-out 0.1s;
    -moz-transition: 2s all ease-in-out 0.1s;
    -ms-transition: 2s all ease-in-out 0.1s;
    -o-transition: 2s all ease-in-out 0.1s;
    transition: 2s all ease-in-out 0.1s;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#rss {
    font-size: 1.4em !important;
}

.filter-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.filter-header h1 {
    flex: 1 1 auto;
    margin-top: 0;
}

.filter-header input {
    flex: 0 0 50%;
}

/* files */

.fileDisplay {
    border-radius: 2px;
    background-color: #f9f9f9;
    border: solid 1px #706f6e;
    margin: 4px;
    padding: 8px;
}

.fileIcon {
    float: left;
}

.fileDisplayHeader {
    float: left;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: $color-secondary;
    overflow: hidden;
}

/* Spinner */
.spinner {
    width: 40px;
    height: 40px;

    position: relative;
    margin: 100px auto;
}

.spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;

    -webkit-animation: sk-rotate 2s infinite linear;
    animation: sk-rotate 2s infinite linear;
}

.loadingApp {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.dot1 {
    background-color: $color-primary;
}

.dot2 {
    top: auto;
    bottom: 0;
    background-color: $color-secondary;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.dropdown-menu.right {
    right: 1px;
    left: auto;
    min-width: 250px;
    width: fit-content;
}

.highlight-fade {
    opacity: 1;
    animation-name: highlightFade;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 3s;
}

.banner {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
    padding: 12px 20px;
    margin-bottom: 16px;
}

@keyframes highlightFade {
    0% {
        opacity: 0;
        background: rgb(255, 255, 210);
    }
    15% {
        opacity: 1;
        background: rgb(255, 255, 210);
    }
    100% {
        background: transparent;
    }
}

.loading-model-msg {
    position: fixed;
    width: 100%;
    color: #333;
    height: 30px;
    text-align: center;
    padding-top: 3px;
    background-color: rgb(255, 255, 210);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    margin-bottom: 0;
    animation: cssAnimation 0.3s 0.8s forwards;
    opacity: 0;
}

@keyframes cssAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.waiting {
    cursor: wait;
}



.matrixOutcome {
    display: block;
    border-width: 2px;
    margin-bottom: 3px;
    border-style: solid;
    border-color: #efefef;
}

.selectedOutcome {
    display: block;
    border-width: 2px;
    margin-bottom: 3px;
    border-style: solid;
    border-color: #efefef;
}

/* ------------ BTN DROPDOWN ------------------*/

.btn-group > .dropdown-menu > li > * {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

alli-web-navigation {
    height: 100%;
}

.header__navigation {
    height: 100%;
}

/* ------------ NGX TOASTR ------------------*/

.toast-success {
    background-color: $color-primary;
}

.toast-error {
    background-color: $color-secondary;
}

/* ------------ utilities ------------------*/

.text-sentence {
    text-transform: lowercase;

    &:first-letter {
        text-transform: capitalize;
    }
}

.text-sentence-mix {
    &:first-letter {
        text-transform: capitalize;
    }
}

.no-after::after {
    content: none !important;
}

#savePatientButton {
    display: none;
}

#feedbacktab {
    position: fixed;
    right: 50px;
    bottom: 0;
    padding: 30px;
    background: $color-primary;
    color: white;
    z-index: 1000;
}

#feedbacktab a {
    color: white;
    font-weight: 700;
}

@media (max-width: 760px) {
    #feedbacktab {
        padding: 8px;
    }
}

/* This overwrites the block-ui wrapper styles */
.block-ui-wrapper {
    //background-color: rgba(129, 198, 184, .5) !important;
    background-color: rgba(0, 0, 0, .5) !important;
}

.outcome-table-modal,
.compare-paragraphs-modal,
.compare-models-modal {
    .modal-dialog {
        min-width: 90vw;
        margin-top: 100px;
    }
}

#loadingContent {
    display: block;
}

.tag,
.cursor-pointer {
    cursor: pointer;
}

.hidden {
    display: none;
}


button:disabled {
    cursor: not-allowed;
}

